import { Helmet } from 'react-helmet';
import { Grid } from "@mui/material"
import Home from "./pages/Home"
import "./App.css"

const App = () => {

  return (
    <div>
      <Helmet>
        <title>Techdataflow</title>
        <meta name="description" content="Techdataflow centralizes and displays all marketing and technical information about a product or technology across different media within a single window in any browser. " />
        <meta name="theme-color" content="#492B7C" />
      </Helmet>
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}><Home/></Grid>
        </Grid>
    </div>
  );
};


export default App
